import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import "../css/App.css";
import trophy from "../img/trophy.png";
type MyProps = {
  scoreBoard?: any;
  totalScoreBoard?: any;
  matches?: any;
};
type MyState = {};
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});
class RoundScoreBoard extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { scoreBoard, matches } = this.props;
    let scores = scoreBoard.scores;
    let length = 12;

    scores?.map((score: any, index: number) => {
      if (index === 0 && score.score > -1) {
        score.color = "#FFD700";
        score.placement = 1;
      }
      if (index > 0 && score.score > -1) {
        if (
          score.score === scores[index - 1].score &&
          score.trophies?.length === scores[index - 1].trophies?.length
        ) {
          score.color = scores[index - 1].color;
          score.placement = scores[index - 1].placement;
        } else {
          if (index === 1) {
            score.color = "#C0C0C0";
            score.placement = 2;
          } else if (index === 2) {
            score.color = "#CD7F32";
            score.placement = 3;
          } else {
            score.placement = index + 1;
          }
        }
      }
    });
    scores?.forEach((score: any) => {
      score.userBetCount = 0;
    });
    matches?.forEach((match: any) => {
      match?.matchResult?.userBets?.forEach((userBet: any) => {
        scores
          .filter((userScore: any) => userScore.userId === userBet.userId)
          .forEach((score: any) => {
            score.userBetCount = score.userBetCount + 1;
          });
      });
    });
    scores?.forEach((score: any) => {
      if (score.userBetCount === 4) {
        score.betColor = "#0ceb17";
      } else if (score.userBetCount === 0) {
        score.betColor = "#e31212";
      } else {
        score.betColor = "#f5970a";
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <Card
          sx={{
            width: 400,
            maxWidth: "100vw",
            margin: 1,
            boxShadow: "8px 10px 25px 1px #000000",
            borderRadius: "10px",
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              className="smile"
              component="div"
            >
              <span>
                <span className={"roundHeader"}>
                  {scoreBoard && scores[0].score > -1
                    ? "Round result:"
                    : scoreBoard
                    ? "Has placed bet this round"
                    : ""}
                </span>
              </span>
            </Typography>
            {scores
              ? //for each user
                scores.map((userScore: any, index: number) => (
                  <Typography
                    key={userScore.userId + "" + index}
                    variant="body1"
                    color={
                      userScore.score > -1
                        ? userScore.color
                        : userScore.betColor
                    }
                  >
                    <span className="scoreBoard">
                      <span>
                        {userScore.score > -1 ? userScore.placement + ". " : ""}
                        {userScore.userName.substring(0, length) +
                          (userScore.userName.length >= length ? "..." : "")}
                      </span>
                      {scoreBoard &&
                      userScore.score > -1 &&
                      userScore.placement === 1 ? (
                        <span>
                          <img src={trophy} className="trophyLogo" alt="logo" />
                        </span>
                      ) : scoreBoard &&
                        userScore.score === -1 &&
                        userScore.userBetCount === 0 ? (
                        "Not placed any bets."
                      ) : scoreBoard &&
                        userScore.score === -1 &&
                        userScore.userBetCount === 4 ? (
                        "Placed all bets."
                      ) : scoreBoard &&
                        userScore.score === -1 &&
                        userScore.userBetCount > 0 ? (
                        "Partially placed bets."
                      ) : (
                        <span />
                      )}
                      <span>{userScore.score > -1 ? userScore.score : ""}</span>
                    </span>
                  </Typography>
                ))
              : ""}
            <Typography
              gutterBottom
              variant="body1"
              className="scoreBoard1"
              component="div"
            >
              <span />
              <span />
              <span className="sc1">
                {<span>Round winner(s): </span>}
                <img src={trophy} className="trophyLogo" alt="logo" />
              </span>
            </Typography>
          </CardContent>
        </Card>
      </ThemeProvider>
    );
  }
}

export default RoundScoreBoard;
