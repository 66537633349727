import moment from "moment";
import { Component } from "react";
import MatchCard from "./components/MatchCard";
//import helpers from './helpers/APIHelper.js';
// import leaguefixtures from "./helpers/leaguefixtures.json";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Account from "./components/Account";
import Clock from "./components/Clock";
import LeagueStandings from "./components/LeagueStandings";
import Loading from "./components/Loading";
import Results from "./components/Results";
import RoundInfo from "./components/RoundInfo";
import SignIn from "./components/SignIn";
import "./css/App.css";
import {
  fetchAllMatches,
  fetchLeagueDetails,
  fetchLeaguefixtures,
  fetchTeamsDetails,
} from "./helpers/APIHelper";

export interface TeamDTO {
  leagueId: number;
  teamId: number;
  teamName: string;
}

export interface TeamsDTO {
  teams: TeamDTO[];
  userId: number;
  userName: string;
}

type MyState = {
  currentUser: any;
  matches: any[];
  myBets: any[];
  round: number;
  seasonId: number;
  currentSeasonId: number;
  mainRound: number;
  tabIndex: any;
  deadline: any;
  leagueDetails: any;
  leaguefixtures: any;
  teamsDetails: TeamsDTO | null | undefined;
  activeTeam: TeamDTO | null | undefined;
  loading: boolean;
  hasInited: boolean;
};

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});
class App extends Component<any, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentUser: null,
      matches: [],
      myBets: [],
      round: 1,
      seasonId: 78,
      currentSeasonId: 0,
      mainRound: 1,
      tabIndex: 0,
      deadline: "January, 5, 2022, 13:42:20 GMT",
      leagueDetails: {},
      leaguefixtures: {},
      teamsDetails: null,
      activeTeam: null,
      loading: true,
      hasInited: false,
    };
  }

  async getLeagueDetails() {
    const { currentUser } = this.state;
    const leagueDetails = await fetchLeagueDetails(
      currentUser.userToken,
      19677
    );
    this.setState({
      leagueDetails: leagueDetails,
    });
  }

  async componentWillMount() {
    //Logged in stuff

    const token = window.localStorage.getItem("token");
    if (token != null) {
      const id = window.localStorage.getItem("id");
      const name = window.localStorage.getItem("name");
      const email = window.localStorage.getItem("email");

      this.setState(
        {
          currentUser: {
            userName: name,
            userEmail: email,
            userId: id,
            userToken: token,
          },
        },
        () => {
          this.setTeamDetails();
        }
      );
    } else {
      this.setTabIndex(2);
    }

    //Get round stuff
    var today = new Date(); //get the day
    let dayOfWeek = moment(today).day(); // get the day of week (0 = sunday)
    let differenceBetweenDates = 6 - dayOfWeek;
    let thisWeekMatchDate =
      dayOfWeek > 0
        ? today.setDate(today.getDate() + differenceBetweenDates)
        : today.setDate(today.getDate() - 1);

    //thisWeekMatchDate.setHours(10)
    //January, 5, 2022, 13:09:50 GMT
    //January, 8, 2022, 15:59:59 GMT+1
    let matchDay = new Date(thisWeekMatchDate);
    matchDay.setHours(15, 55, 0);
    let bettingDeadline =
      moment(matchDay).format("MMMM, D, YYYY, HH:mm:ss") + " GMT+1";

    this.setState({
      deadline: bettingDeadline,
      loading: false,
    });
  }

  setTeamDetails = async () => {
    const { currentUser, seasonId, hasInited, round } = this.state;
    const teamId = window.localStorage.getItem("teamId");

    const teamsDetails: TeamsDTO = await fetchTeamsDetails(
      currentUser.userToken
    ).then((stuff) => stuff);

    let leagueDetails = {};
    let activeTeam = null;
    let currentMatches = [];
    let leaguefixtures: any = {};
    let currentSeasonId = 0;
    let myBets = [];

    var today = new Date(); //get the day
    let dayOfWeek = moment(today).day(); // get the day of week (0 = sunday)
    let differenceBetweenDates = 6 - dayOfWeek;
    let thisWeekMatchDate =
      dayOfWeek > 0
        ? today.setDate(today.getDate() + differenceBetweenDates)
        : today.setDate(today.getDate() - 1);

    if (teamsDetails === null || teamsDetails === undefined) {
      //Alert some message to Re CHPP
    } else if (teamsDetails?.teams?.length > 0) {
      if (teamId === null || teamId === undefined) {
        activeTeam = teamsDetails.teams[0];
        window.localStorage.setItem("teamId", "" + activeTeam!.teamId);
        leagueDetails = await fetchLeagueDetails(
          currentUser.userToken,
          activeTeam!.leagueId
        );
      } else {
        activeTeam = teamsDetails.teams.find(
          (team) => team.teamId! === +teamId!
        );

        leagueDetails = await fetchLeagueDetails(
          currentUser.userToken,
          activeTeam!.leagueId
        );
      }

      leaguefixtures = await fetchLeaguefixtures(
        currentUser.userToken,
        activeTeam!.leagueId,
        seasonId
      ).then((stuff: any) => stuff);

      if (leaguefixtures) {
        if (!hasInited) {
          currentSeasonId = leaguefixtures.season;
        }
        const allMatchesWithBettings = await fetchAllMatches(
          currentUser.userToken,
          activeTeam!.leagueId,
          seasonId
        );
        const roundMatches = allMatchesWithBettings.find(
          (roundMatches: any) => {
            return roundMatches.roundId === round;
          }
        );
        myBets = roundMatches.matches.map(
          (match: { userBets: any[]; matchId: any }) => {
            const homeScore = match.userBets.filter((userBet: any) => {
              return userBet.userId === +currentUser.userId;
            })[0]?.homeScore;

            const awayScore = match.userBets.find(
              (userBet: { userId: any }) =>
                userBet.userId === +currentUser.userId
            )?.awayScore;
            return {
              homeScore: homeScore,
              awayScore: awayScore,
              matchId: match.matchId,
            };
          }
        );
        currentMatches = leaguefixtures?.matches?.filter(
          (match: { matchDate: moment.MomentInput }) => {
            return (
              moment(thisWeekMatchDate).format("DD.MM.YY") ===
              moment(match.matchDate).format("DD.MM.YY")
            );
          }
        );
        if (
          currentMatches === null ||
          currentMatches === undefined ||
          currentMatches?.length === 0
        ) {
          currentMatches = leaguefixtures?.matches?.filter(
            (match: { matchRound: number }) => {
              return match.matchRound === 1;
            }
          );
        }
        if (currentMatches !== null && currentMatches !== undefined) {
          let matchDay = new Date(currentMatches[0].matchDate);
          matchDay.setHours(15, 55, 0);
          let bettingDeadline =
            moment(matchDay).format("MMMM, D, YYYY, HH:mm:ss") + " GMT+1";
          this.setState({
            deadline: bettingDeadline,
          });
        }
      }
    }
    this.setState({
      matches: currentMatches,
      round: currentMatches[0]?.matchRound ?? 1,
      mainRound: currentMatches[0]?.matchRound,
      seasonId: leaguefixtures?.season,
      teamsDetails: teamsDetails,
      leaguefixtures: leaguefixtures,
      activeTeam: activeTeam,
      leagueDetails: leagueDetails,
      tabIndex: activeTeam ? 0 : 2,
      hasInited: true,
      currentSeasonId: currentSeasonId,
      myBets: myBets,
    });
  };

  logout = () => {
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("teamId");
    this.setState({ currentUser: null });
  };

  setUser = async (user: any) => {
    if (user) {
      window.localStorage.setItem("id", user.userId);
      window.localStorage.setItem("name", user.userName);
      window.localStorage.setItem("email", user.userEmail);
      window.localStorage.setItem("token", user.userToken);

      this.setState(
        {
          currentUser: user,
          tabIndex: 0,
          loading: false,
        },
        () => this.setTeamDetails()
      );
    }
  };

  setTeam = async (teamsDto: TeamsDTO) => {
    const { currentUser } = this.state;
    if (teamsDto !== null && teamsDto !== undefined) {
      let leagueDetails = {};
      let activeTeam = null;

      if (teamsDto?.teams?.length > 0) {
        activeTeam = teamsDto.teams[0];
        leagueDetails = await fetchLeagueDetails(
          currentUser.userToken,
          activeTeam!.leagueId
        );
      }
      this.setState({
        teamsDetails: teamsDto,
        activeTeam: activeTeam,
        leagueDetails: leagueDetails,
        tabIndex: activeTeam ? 0 : 2,
      });
    }
  };

  handleSelectSeason = async (event: SelectChangeEvent) => {
    const newSeasonId = event.target.value;
    this.setState(
      {
        seasonId: +newSeasonId,
      },
      () => {
        this.setTeamDetails();
      }
    );
  };

  handleActiveTeam = async (event: SelectChangeEvent) => {
    const { teamsDetails, currentUser } = this.state;
    const newId = event.target.value;
    const newActiveTeam = teamsDetails?.teams.find(
      (team) => team.teamId === +newId
    );
    window.localStorage.setItem("teamId", "" + newActiveTeam!.teamId);
    const leagueDetails = await fetchLeagueDetails(
      currentUser.userToken,
      newActiveTeam!.leagueId
    );
    this.setState({
      activeTeam: newActiveTeam,
      leagueDetails: leagueDetails,
    });
  };

  nextRound = () => {
    let round = this.state.round;
    round = round + 1;
    if (round > 14) {
      round = 14;
    }
    this.setRound(round);
  };

  previousRound = () => {
    let round = this.state.round;
    round = round - 1;
    if (round < 1) {
      round = 1;
    }
    this.setRound(round);
  };

  async setRound(round: number) {
    const { leaguefixtures } = this.state;
    let currentMatches = leaguefixtures?.matches.filter(
      (match: { matchRound: number }) => {
        return match.matchRound === round;
      }
    );
    this.setState({ round: round, matches: currentMatches });
  }

  setTabIndex = (tabIndex: any) => {
    this.setState({ tabIndex: tabIndex });
  };

  render() {
    const {
      mainRound,
      round,
      seasonId,
      matches,
      currentUser,
      tabIndex,
      leagueDetails,
      teamsDetails,
      activeTeam,
      loading,
      currentSeasonId,
      myBets,
    } = this.state;

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="testyfesty">
            <div>Tippeligaen!</div>
            {teamsDetails && activeTeam && (
              <Select
                sx={{
                  mt: 2,
                  ml: 1,
                  color: "white",
                }}
                labelId="selectSeasonLabel"
                id="selectSeason"
                defaultValue="78"
                label="Select season"
                onChange={this.handleSelectSeason}
              >
                <MenuItem key={78} value={"" + 78}>
                  {78}
                </MenuItem>
                <MenuItem key={77} value={"" + 77}>
                  {77}
                </MenuItem>
                <MenuItem key={76} value={"" + 76}>
                  {76}
                </MenuItem>
              </Select>
            )}
          </h1>
        </header>
        {teamsDetails && activeTeam && (
          <>
            <span className="teamDropdown">Your team:</span>
            <Select
              sx={{
                mt: 2,
                ml: 1,
                color: "success.main",
              }}
              labelId="selectTeamLabel"
              id="selectTeam"
              value={"" + activeTeam.teamId}
              label="Select team"
              onChange={this.handleActiveTeam}
            >
              {teamsDetails.teams.map((team) => (
                <MenuItem key={team.teamId} value={"" + team.teamId}>
                  {team.teamName}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        <div className="App-body">
          {loading ? (
            <Loading />
          ) : currentUser == null ? (
            <SignIn onLogin={this.setUser} />
          ) : (
            <div className="white-text miip">
              {(() => {
                switch (tabIndex) {
                  case 0: //Matches
                    return (
                      <div className="body-wrapper">
                        <Clock deadline={this.state.deadline} />

                        <RoundInfo
                          onMinusRound={() => {
                            this.previousRound();
                          }}
                          onPlusRound={() => {
                            this.nextRound();
                          }}
                          numberOfMatches={matches.length}
                          thisRound={mainRound === round}
                          roundId={round}
                        />
                        {!activeTeam && <span>You have not connected </span>}
                        <div className="Match-body">
                          {matches.map((match) => (
                            <MatchCard
                              leagueDetails={leagueDetails}
                              key={match.matchId}
                              match={match}
                              roundId={round}
                              seasonId={seasonId}
                              currentSeasonId={currentSeasonId}
                              mainRound={mainRound}
                              matchBet={myBets.find(
                                (bet) => bet.matchId === match.matchId
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  case 1: //Results
                    return (
                      <div className="body-wrapper">
                        <Clock deadline={this.state.deadline} />

                        <RoundInfo
                          onMinusRound={() => {
                            this.previousRound();
                          }}
                          onPlusRound={() => {
                            this.nextRound();
                          }}
                          numberOfMatches={matches.length}
                          thisRound={mainRound === round}
                          roundId={round}
                        />

                        <Results
                          currentUser={currentUser}
                          matches={matches}
                          roundId={round}
                          seasonId={seasonId}
                          activeTeam={activeTeam}
                        />
                      </div>
                    );
                  case 2: //Account
                  default:
                    return (
                      <Account
                        currentUser={currentUser}
                        onUpdate={this.setUser}
                        onVerify={this.setTeam}
                        onLogout={this.logout}
                        activeTeam={activeTeam}
                      />
                    );
                  case 3: //League standings
                    return (
                      <LeagueStandings
                        leagueDetails={leagueDetails}
                        activeTeam={activeTeam}
                      />
                    );
                }
              })()}
            </div>
          )}
        </div>
        {currentUser != null ? (
          <ThemeProvider theme={theme}>
            <Box sx={{ width: 500 }}>
              <BottomNavigation
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                }}
                showLabels
                value={tabIndex}
                onChange={(event, tabIndex) => {
                  this.setTabIndex(tabIndex);
                }}
              >
                {activeTeam && (
                  <BottomNavigationAction
                    label="Matches"
                    icon={<SportsSoccerIcon />}
                  />
                )}
                {activeTeam && (
                  <BottomNavigationAction
                    label="Results"
                    icon={<ContentPasteIcon />}
                  />
                )}
                <BottomNavigationAction
                  label="Account"
                  icon={<AccountCircleIcon />}
                />
                {activeTeam && (
                  <BottomNavigationAction
                    label="Standings"
                    icon={<FormatListNumberedIcon />}
                  />
                )}
              </BottomNavigation>
            </Box>
          </ThemeProvider>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default App;
