import { Box, Button, TextField } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { gsap } from "gsap";
import * as React from "react";
import { Component } from "react";
import "../css/App.css";
import { baseURL } from "../helpers/BaseUrls";
import bot from "../img/bot_team_logo.png";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

type MyProps = {
  match: any;
  roundId: number;
  seasonId: number;
  currentSeasonId: number;
  mainRound: number;
  leagueDetails: any;
  matchBet: any;
};
type MyState = {
  open: boolean;
  saved: any;
  homeScore: number;
  awayScore: number;
};
class MatchCard extends Component<MyProps, MyState> {
  private boxRef: React.RefObject<HTMLDivElement>;
  constructor(props: MyProps) {
    super(props);
    this.boxRef = React.createRef();

    this.state = {
      open: false,
      saved: {
        message: "Ventetekst!",
        severity: "info",
      },
      homeScore: 0,
      awayScore: 0,
    };
  }

  componentDidMount() {
    const { matchBet } = this.props;

    if (matchBet && matchBet.homeScore && matchBet.awayScore) {
      this.setState({
        homeScore: matchBet.homeScore,
        awayScore: matchBet.awayScore,
      });
    }
    gsap.fromTo(
      this.boxRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 2 }
    );
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event: any) => {
    if (event.reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  handleSubmitGuess = (event: React.FormEvent<HTMLFormElement>) => {
    const { match, roundId, seasonId } = this.props;
    const token = window.localStorage.getItem("token") as string;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const homeScore = data.get("home") || 0;
    const awayScore = data.get("away") || 0;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        matchId: match.matchId,
        userId: parseInt(window.localStorage.getItem("id") || "0"),
        roundId: roundId,
        seasonId: seasonId,
        homeScore: +homeScore,
        awayScore: +awayScore,
      }),
    };
    fetch(baseURL() + "/api/betting/add-betting-results", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data === 1) {
          this.setState({
            saved: {
              message: "Tipperesultat oppdatert!",
              severity: "info",
            },
            open: true,
          });
        } else if (data > 1) {
          this.setState({
            saved: { message: "Tipperesultat levert!", severity: "success" },
            open: true,
          });
        } else if (data.message) {
          this.setState({
            saved: { message: data.message, severity: "error" },
            open: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          saved: {
            message: "Something went wrong! Contact Taddisen @hattrick! -> " + error,
            severity: "error",
          },
          open: true,
        });
      });
  };

  handleSubmitResult = (event: React.FormEvent<HTMLFormElement>) => {
    const { match, roundId } = this.props;
    const token = window.localStorage.getItem("token") as string;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
      body: JSON.stringify({
        matchId: match.matchId,
        homeScore: data.get("homeScore"),
        awayScore: data.get("awayScore"),
      }),
    };
    fetch(baseURL() + "/api/betting/add-betting-results", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data === 1) {
          this.setState({
            saved: {
              message: "Matchresult updated!",
              severity: "info",
            },
            open: true,
          });
        } else {
          this.setState({
            saved: {
              message: "Something went wrong! Contact Taddisen @hattrick!",
              severity: "error",
            },
            open: true,
          });
        }
        //onLogin(data)
      })
      .catch((error) => {
        this.setState({
          saved: {
            message: "Something went wrong! Contact Taddisen @hattrick!",
            severity: "error",
          },
          open: true,
        });
      });
  };

  render() {
    const { open, saved, homeScore, awayScore } = this.state;
    const {
      match,
      roundId,
      mainRound,
      leagueDetails,
      seasonId,
      currentSeasonId,
    } = this.props;
    const prevRound: boolean = +roundId < +mainRound;
    let fallBackArena =
      "https://res.hattrick.org/arenas/default/50000/custom-220-100.jpg";
    let fallLogo = "https://www.hattrick.org/v2306812/Images/Club/nologo.png";
    let homeArena = leagueDetails?.teams
      .filter((team: any) => match.homeTeamId === team.teamId)
      .map((team: any) => team.arenaUrl);

    let homeLogo = leagueDetails?.teams
      .filter((team: any) => match.homeTeamId === team.teamId)
      .map((team: any) => team.logoUrl);

    let awayLogo = leagueDetails?.teams
      .filter((team: any) => match.awayTeamId === team.teamId)
      .map((team: any) => team.logoUrl);

    //620-0 is bigger imagesize
    homeLogo = homeLogo.toString().replace("220-100", "620-0");
    awayLogo = awayLogo.toString().replace("220-100", "620-0");
    homeArena = homeArena.toString().replace("220-100", "620-0");
    if (homeArena.length === 0) {
      homeArena = fallBackArena;
    }
    const weirdShit: any = match;
    let oWidth = window.outerWidth;
    let stadiumHeight = 250;
    if (oWidth < 700) {
      stadiumHeight = 140;
    }

    const userId = window.localStorage.getItem("id") as string;

    return (
      <ThemeProvider theme={theme}>
        <Card
          ref={this.boxRef}
          sx={{
            width: 500,
            margin: 1,
            height: 1,
            boxShadow: "8px 10px 25px 1px #000000",
            borderRadius: "10px",
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: stadiumHeight }}
            image={homeArena}
            alt="Team stadium"
            onError={({ currentTarget }: any) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = fallBackArena;
            }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              className="smile"
              component="div"
            >
              <img
                src={homeLogo}
                className="Club-logo"
                alt="logo"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallLogo;
                }}
              />
              <div className="hihi">
                <span className={homeLogo === bot ? "" : "playerColor"}>
                  {match.homeTeamName}{" "}
                </span>
                <br />
                <span className="vsColor"> vs </span>
                <br />
                <span className={awayLogo === bot ? "" : "playerColor"}>
                  {" "}
                  {match.awayTeamName}
                </span>
              </div>
              <img
                src={awayLogo}
                className="Club-logo"
                alt="logo"
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallLogo;
                }}
              />
            </Typography>
            <Box
              component="form"
              onSubmit={this.handleSubmitGuess}
              noValidate
              sx={{ mt: 1 }}
            >
              <div className="test1">
                <TextField
                  disabled={prevRound || currentSeasonId !== seasonId}
                  margin="normal"
                  id="home"
                  label="Home"
                  name="home"
                  type="number"
                  inputProps={{
                    pattern: "[0-9]*",
                  }}
                  value={homeScore}
                  onChange={(e) => {
                    if (+e.target.value > -1) {
                      this.setState({ homeScore: +e.target.value });
                    }
                  }}
                  autoComplete="off"
                />
                <TextField
                  disabled={prevRound || currentSeasonId !== seasonId}
                  margin="normal"
                  id="away"
                  label="Away"
                  name="away"
                  type="number"
                  inputProps={{
                    pattern: "[0-9]*",
                  }}
                  value={awayScore}
                  onChange={(e) => {
                    if (+e.target.value > -1) {
                      this.setState({ awayScore: +e.target.value });
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <Button
                disabled={prevRound || currentSeasonId !== seasonId}
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 1, mb: 0, width: "60%" }}
              >
                Tipp
              </Button>
            </Box>
            {userId === "1" ? (
              <Box
                component="form"
                onSubmit={this.handleSubmitResult}
                noValidate
                sx={{ mt: 1 }}
              >
                <div className="test1">
                  <TextField
                    disabled={prevRound}
                    margin="normal"
                    id="homeScore"
                    label="Hjemme"
                    name="homeScore"
                    type="number"
                    inputProps={{
                      pattern: "[0-9]*",
                    }}
                    autoComplete="off"
                  />
                  <TextField
                    disabled={prevRound}
                    margin="normal"
                    id="awayScore"
                    label="Borte"
                    name="awayScore"
                    type="number"
                    inputProps={{
                      pattern: "[0-9]*",
                    }}
                    autoComplete="off"
                  />
                </div>
                <Button
                  disabled={prevRound}
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ mt: 1, mb: 0, width: "60%" }}
                >
                  Legg inn resultat
                </Button>
              </Box>
            ) : (
              ""
            )}

            <Typography variant="body2" color="#23ef25" sx={{ mt: 1 }}>
              {weirdShit.awayGoals === 0 ||
              (weirdShit.awayGoals && weirdShit.awayGoals > 0)
                ? "Result: " + weirdShit.homeGoals + " - " + weirdShit.awayGoals
                : ""}
            </Typography>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{ mb: 6 }}
          open={open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
        >
          <Alert
            onClose={() => this.setState({ open: false })}
            severity={saved.severity}
            sx={{ width: "100%" }}
          >
            {saved.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

export default MatchCard;
