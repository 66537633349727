import { Button } from "@mui/material";
import { Component } from "react";
import "../css/App.css";

type MyProps = {
  onMinusRound: any;
  onPlusRound: any;
  numberOfMatches: number;
  thisRound: boolean;
  roundId: number;
};
type MyState = {};
class RoundInfo extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { onMinusRound, onPlusRound, numberOfMatches, thisRound, roundId } =
      this.props;

    return (
      <div className="roundInfo cam days white-text">
        <span>
          Here are the {numberOfMatches ?? 0} matches{" "}
          {thisRound ? "this round" : "in round " + roundId}.
        </span>
        <div className="cam2">
          <Button variant="contained" onClick={onMinusRound}>
            Last round
          </Button>
          <Button variant="contained" onClick={onPlusRound}>
            Next round
          </Button>
        </div>
      </div>
    );
  }
}

export default RoundInfo;
