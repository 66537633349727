import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "../css/App.css";
import { baseURL } from "../helpers/BaseUrls";
import { Link } from "react-router-dom";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});
enum AlertMessage {
  Incorrect = "Wrong username or password!",
  RegisterFailed = "Username might be taken..",
  UserFail = "Username must at least be 2 characters..",
  PassFail = "Password must be filled in..",
  EmailFail = "Email must be filled in..",
}

export default function SignIn(props: any) {
  const [open, setOpen] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState(AlertMessage.Incorrect);
  const [showLogin, setShowLogin] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const { onLogin } = props;

  const handleSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userName: data.get("username"),
        password: data.get("password"),
      }),
    };
    fetch(baseURL() + "/api/account/login", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong.");
      })
      .then((data) => {
        onLogin(data);
      })
      .catch((error) => {
        handleClick();
      });
  };

  const handleSubmitRegister = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    if (data.has("username") && data.get("username")!.toString().length < 2) {
      setAlertMsg(AlertMessage.UserFail);
      setOpen(true);
      return;
    }
    if (data.has("password") && data.get("password")!.toString().length < 1) {
      setAlertMsg(AlertMessage.PassFail);
      setOpen(true);
      return;
    }
    if (data.has("email") && data.get("email")!.toString().length < 7) {
      setAlertMsg(AlertMessage.EmailFail);
      setOpen(true);
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userName: data.get("username"),
        userEmail: data.get("email"),
        password: data.get("password"),
      }),
    };
    fetch(baseURL() + "/api/account/register", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong.");
      })
      .then((data) => {
        onLogin(data);
      })
      .catch((error) => {
        setAlertMsg(AlertMessage.RegisterFailed);
        handleClick();
      });
  };

  const handleShowRegister = (event: any) => {
    event.preventDefault();
    setOpen(false);
    setShowLogin((old) => {
      if (old) {
        setAlertMsg(AlertMessage.RegisterFailed);
      } else {
        setAlertMsg(AlertMessage.Incorrect);
      }
      return !old;
    });
  };
  const handleForgotRedirect = (event: any) => {
    event.preventDefault();
  };

  /*
    forgot pw later
    below 
                  Sign In
                </Button>
    
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
    
    */
  return (
    <ThemeProvider theme={theme}>
      <Container className="white-bg" component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              m: 1,
              bgcolor: "success.main",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {showLogin ? "Log in" : "Register"}
          </Typography>
          {showLogin ? (
            <Box
              component="form"
              onSubmit={handleSubmitLogin}
              noValidate
              sx={{
                mt: 1,
                color: "success.main",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Log in
              </Button>
              <Button
                type="button"
                fullWidth
                onClick={handleShowRegister}
                variant="text"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Register
              </Button>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmitRegister}
              noValidate
              sx={{
                mt: 1,
                color: "success.main",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                autoComplete="off"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Register
              </Button>
              <Button
                type="button"
                fullWidth
                onClick={handleShowRegister}
                variant="text"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Log in
              </Button>
            </Box>
          )}

          <Link type="button" to="/forgottenPassword">
            <Button
              type="button"
              fullWidth
              variant="text"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Forgot password
            </Button>
          </Link>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
