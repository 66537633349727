import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import "../css/App.css";
import l1072363 from "../img/1072363.png";
import l1075072 from "../img/1075072.png";
import l1076796 from "../img/1076796.png";
import bot from "../img/bot_team_logo.png";

type MyProps = {
  match: any;
  roundId: number;
};
type MyState = {};
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});
class RoundBoard extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { match } = this.props;
    const results = match.matchResult;
    if (results?.userBets) {
      results.userBets = results.userBets.sort((a: any, b: any) => {
        if (a.points || b.points) {
          return a.points > b.points ? -1 : 1;
        }
        return a.userName < b.userName ? -1 : 1;
      });
    }

    const teamArray = ["l1072363", "l1075072", "l1076796"];
    const homeId = "l" + match.homeTeamId;
    const awayId = "l" + match.awayTeamId;
    let homeSrc = bot;
    if (teamArray.includes(homeId)) {
      if (homeId === "l1072363") {
        homeSrc = l1072363;
      } else if (homeId === "l1075072") {
        homeSrc = l1075072;
      } else {
        homeSrc = l1076796;
      }
    }
    let awaySrc = bot;
    if (teamArray.includes(awayId)) {
      if (awayId === "l1072363") {
        awaySrc = l1072363;
      } else if (awayId === "l1075072") {
        awaySrc = l1075072;
      } else {
        awaySrc = l1076796;
      }
    }

    let homeName = match.homeTeamName;
    let awayName = match.awayTeamName;
    let text = homeName + " vs " + awayName;
    let length = 30;
    let oWidth = 400 - (16 + 8) * 2;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "italic small-caps bold  1.3rem Arial";
    }
    let width = ctx!.measureText(text).width;
    while (width > oWidth) {
      length = length - 1;
      homeName = homeName.substring(0, length);
      awayName = awayName.substring(0, length);
      text = homeName + ".. vs " + awayName + "..";
      width = ctx!.measureText(text).width;
    }
    return (
      <ThemeProvider theme={theme}>
        <Card
          sx={{
            width: 400,
            maxWidth: "100vw",
            margin: 1,
            boxShadow: "8px 10px 25px 1px #000000",
            borderRadius: "10px",
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              sx={{ margin: 0, padding: 0 }}
              variant="h6"
              className="smile"
              component="span"
            >
              <span>
                <span className={homeSrc === bot ? "" : "playerColor"}>
                  {" "}
                  {homeName.substring(0, length) +
                    (homeName.length >= length ? ".." : "")}{" "}
                </span>
                <span className="grey"> vs </span>
                <span className={awaySrc === bot ? "" : "playerColor"}>
                  {" "}
                  {awayName.substring(0, length) +
                    (awayName.length >= length ? ".." : "")}
                </span>
              </span>
            </Typography>
            <Typography
              variant="body1"
              component={"span"}
              color={results?.homeGoals >= 0 ? "success.main" : "error.main"}
            >
              <span className="testyMctest">
                <span>
                  {results?.homeGoals != null
                    ? "Match results"
                    : "Results not ready."}
                </span>
                <span>
                  {results?.homeGoals != null
                    ? results.homeGoals + " - " + results.awayGoals
                    : "X - X"}
                </span>
                <span>{results?.homeGoals != null ? "Points" : ""}</span>
              </span>
            </Typography>
            {results && results.userBets
              ? results.userBets.map((userBet: any, index: number) => (
                  <Typography
                    key={userBet.userId + "score"}
                    variant="body2"
                    component={"span"}
                    color="text.secondary"
                  >
                    <span className="testyMctest">
                      <span>
                        {index +
                          1 +
                          ". " +
                          userBet.userName.substring(0, length + 5) +
                          (userBet.userName.length > length + 5 ? "..." : "")}
                      </span>
                      <span>
                        {userBet.homeScore != null
                          ? userBet.homeScore + " - " + userBet.awayScore
                          : "X - X"}
                      </span>
                      <span>{userBet.points}</span>
                    </span>
                  </Typography>
                ))
              : ""}
          </CardContent>
        </Card>
      </ThemeProvider>
    );
  }
}

export default RoundBoard;
