import { createTheme } from "@mui/material";
import { Component } from "react";
import "../css/App.css";
import "../css/Loading.css";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

type MyProps = {
  text?: string;
};
type MyState = {};

class Loading extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { text } = this.props;
    return (
      <div>
        <h2 className="App-header">{text ? text : "Laster inn..."}</h2>
        <div className="spinner-container">
          <div className="loading-spinner" />
        </div>
      </div>
    );
  }
}

export default Loading;
