import { Component } from "react";
type MyProps = { deadline: any };
type MyState = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

class Clock extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentWillMount() {
    this.getTimeUntil(this.props.deadline);
  }

  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }

  leading0(num: any) {
    return num; // < 10 ? "0" + num : num;
  }

  getTimeUntil(deadline: any) {
    const time = Date.parse(deadline) - Date.parse(new Date().toString());
    if (time < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    let allEmpty = days === 0 && hours === 0 && minutes === 0 && seconds === 0;
    let color = days === 0 ? "shortTime" : days < 2 ? "mediumTime" : "goodTime";
    return (
      <div className={"clock-body " + color}>
        {allEmpty ? (
          "Deadline has passed. Waiting for results."
        ) : (
          <div>
            Tippedeadline:
            {days > 0 ? (
              <span className="Clock-days">
                {" " + this.leading0(days)} {days === 1 ? "day" : "days"}
              </span>
            ) : (
              ""
            )}
            {hours > 0 ? (
              <span className="Clock-hours">
                {" " + this.leading0(hours)} {hours === 1 ? "hour" : "hours"}
              </span>
            ) : (
              ""
            )}
            {minutes > 0 ? (
              <span className="Clock-minutes">
                {" " + this.leading0(minutes)}{" "}
                {minutes === 1 ? "minute" : "minutes"} and
              </span>
            ) : (
              ""
            )}
            {seconds > 0 ? (
              <span className="Clock-seconds">
                {" " + this.leading0(seconds)}{" "}
                {seconds === 1 ? "second." : "seconds."}
              </span>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}
export default Clock;
