import {
  Box,
  Button,
  createTheme,
  Snackbar,
  TextField,
  ThemeProvider,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import * as React from "react";
import { Component } from "react";
import { TeamDTO } from "../App";
import "../css/App.css";
import { baseURL } from "../helpers/BaseUrls";
import Loading from "./Loading";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

type MyProps = {
  currentUser?: any;
  activeTeam: TeamDTO | null | undefined;
  onUpdate: any;
  onVerify: any;
  onLogout: any;
};
type MyState = {
  userEmail: string;
  userPassword: string;
  authUrl: string | void;
  teamName: string | void;
  showSubmitChpp: boolean;
  open: boolean;
  saved: any;
  loading: boolean;
};

class Account extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      userEmail: props.currentUser.userEmail,
      userPassword: "",
      authUrl: "",
      teamName: "",
      showSubmitChpp: false,
      open: false,
      loading: false,
      saved: {
        message: "Ventetekst!",
        severity: "info",
      },
    };
  }
  // const { currentUser, onLogout } = this.props;
  async componentDidMount(): Promise<void> {
    const { currentUser, activeTeam } = this.props;
    if (!activeTeam) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "text/plain",
          "x-auth-token": currentUser.userToken,
        },
      };
      const url = await fetch(
        baseURL() + "/api/account/getAuthUrl",
        requestOptions
      )
        .then((response) => response.text())
        .then((response) => {
          return response;
        })
        .catch((error) => console.error(error));
      this.setState({ authUrl: url });
    }
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event: any) => {
    if (event.reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  handleUpdateProfile = (event: React.FormEvent<HTMLFormElement>) => {
    const { onUpdate, currentUser } = this.props;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": currentUser.userToken,
      },
      body: JSON.stringify({
        userEmail: data.get("email"),
        password: data.get("password"),
      }),
    };
    fetch(baseURL() + "/api/account/updateProfile", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong.");
      })
      .then((data) => {
        onUpdate(data);
        this.setState({
          saved: {
            message: "Updated profile!",
            severity: "success",
          },
          open: true,
        });
      })
      .catch((error) => {
        // handleClick();
        this.setState({
          saved: {
            message: "Something went wrong updating!",
            severity: "error",
          },
          open: true,
        });
      });
  };

  handleConnectCHPP = (event: any) => {
    const { currentUser } = this.props;
    const { authUrl, showSubmitChpp } = this.state;
    event.preventDefault();
    window.open(authUrl!, "_blank");
    this.setState({ showSubmitChpp: !showSubmitChpp });
  };

  handleVerifyCHPP = async (event: any) => {
    const { currentUser, onVerify } = this.props;
    event.preventDefault();
    this.setState({ loading: true });
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": currentUser.userToken,
      },
      body: JSON.stringify({
        chppPin: data.get("chppPin"),
      }),
    };
    await fetch(baseURL() + "/api/account/verifyPin", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        onVerify(data);
        this.setState({
          loading: false,
          saved: {
            message: "Verified profile!",
            severity: "success",
          },
          open: true,
        });
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { currentUser } = this.props;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": currentUser.userToken,
      },
      body: JSON.stringify({
        roundId: data.get("roundId"),
      }),
    };
    fetch(baseURL() + "/api/tipping/updateRound", requestOptions)
      .then((response) => {
        if (response.ok) {
          this.setState({
            saved: {
              message: "Runde manuelt endret!",
              severity: "success",
            },
            open: true,
          });
        }
        return;
      })
      .catch((error) => {
        this.setState({
          saved: {
            message: "Something went wrong! " + error.message,
            severity: "error",
          },
          open: true,
        });
      });
  };

  render() {
    const { currentUser, onLogout, activeTeam } = this.props;
    const {
      open,
      loading,
      saved,
      userEmail,
      userPassword,
      authUrl,
      showSubmitChpp,
      teamName,
    } = this.state;

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Box
            component="form"
            onSubmit={this.handleUpdateProfile}
            noValidate
            sx={{
              mt: 1,
              color: "success.main",
            }}
          >
            <TextField
              disabled
              margin="normal"
              fullWidth
              value={currentUser.userName}
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
              value={userEmail}
              margin="normal"
              onChange={(e) => this.setState({ userEmail: e.target.value })}
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="off"
            />
            <TextField
              value={userPassword}
              margin="normal"
              fullWidth
              onChange={(e) => this.setState({ userPassword: e.target.value })}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="off"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Update
            </Button>
            {teamName!.length < 1 && authUrl && !showSubmitChpp && (
              <Button
                type="button"
                variant="contained"
                color="success"
                fullWidth
                onClick={this.handleConnectCHPP}
              >
                Connect to CHPP
              </Button>
            )}
          </Box>
        )}
        {!activeTeam && authUrl && showSubmitChpp && (
          <Box
            component="form"
            onSubmit={this.handleVerifyCHPP}
            noValidate
            sx={{
              mt: 1,
              color: "success.main",
            }}
          >
            <TextField
              id="chppPin"
              label="CHPP Code"
              name="chppPin"
              type="text"
              autoComplete="off"
            />
            <Button type="submit" variant="contained" color="success" fullWidth>
              Verify
            </Button>
          </Box>
        )}
        <div className="userinfo">
          <Button
            className="userinfo"
            variant="outlined"
            color="error"
            onClick={onLogout}
          >
            Logg ut
          </Button>
        </div>
        <ThemeProvider theme={theme}>
          {currentUser.userId === "1" ? (
            <Box
              component="form"
              onSubmit={this.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                disabled={currentUser.userId !== "1"}
                margin="normal"
                id="roundId"
                label="Runde"
                name="roundId"
                type="number"
                inputProps={{
                  pattern: "[0-9]*",
                }}
                autoComplete="off"
              />
              <Button
                disabled={currentUser.userId !== "1"}
                type="submit"
                variant="contained"
                color="success"
                sx={{ mt: 1, mb: 0, width: "60%" }}
              >
                Oppdater
              </Button>
            </Box>
          ) : (
            ""
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
          >
            <Alert
              onClose={() => this.setState({ open: false })}
              severity={saved.severity}
              sx={{ width: "100%", mb: 6 }}
            >
              {saved.message}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </div>
    );
  }
}

export default Account;
