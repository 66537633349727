import { baseURL } from "./BaseUrls";

export const fetchAllFixtures = (round, token) => {
  var url = baseURL() + "/api/betting/get-all-fixtures";

  return fetch(url, {
    headers: {
      "x-auth-token": token,
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

export const fetchMatches = (round, token) => {
  var url = baseURL() + "/api/tipping/" + round;

  return fetch(url, {
    headers: {
      "x-auth-token": token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};
export const fetchAllMatches = (token, leagueId, seasonId) => {
  var url =
    baseURL() + "/api/betting/get-all-rounds/" + leagueId + "/" + seasonId;

  return fetch(url, {
    headers: {
      "x-auth-token": token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

export const fetchTeamsDetails = (token) => {
  var url = baseURL() + "/api/account/getTeams";

  return fetch(url, {
    headers: {
      "x-auth-token": token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

export const fetchLeaguefixtures = (token, leagueId, seasonNr) => {
  var url =
    baseURL() + "/api/league/get-all-fixtures/" + leagueId + "/" + seasonNr;
  return fetch(url, {
    headers: {
      "x-auth-token": token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

export const fetchLeagueDetails = (token, leagueId) => {
  var url = baseURL() + "/api/league/get-league/" + leagueId;

  return fetch(url, {
    headers: {
      "x-auth-token": token,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

export const getAllLeagueUsers = (token, leagueId) => {
  var url = baseURL() + "/api/betting/getUsersForLeague/" + leagueId;
  return fetch(url, {
    headers: {
      "x-auth-token": token,
    },
  })
    .then(function (response) {
      return response.json();
    })
    .catch(function (error) {
      console.log("error", error);
    });
};
