import { createTheme } from "@mui/material";
import { Component } from "react";
import { TeamDTO } from "../App";
import "../css/Test.css";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

type MyProps = {
  leagueDetails: any;
  activeTeam: TeamDTO | null | undefined;
};
type MyState = {};

class LeagueStandings extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { leagueDetails, activeTeam } = this.props;
    const teams = leagueDetails.teams.sort((a: any, b: any) =>
      a.points < b.points ? 1 : -1
    );

    return (
      <div className="standings-view">
        <h2>League standings for {leagueDetails.leagueLevelUnitName}</h2>
        <div className="standings-stage">
          <table className="stage-tabsle stage-table--football">
            <thead>
              <tr className="stage-table-row stage-table-header">
                <th className="cell-rank"></th>
                <th className="participant-logo"></th>
                <th className="header-stage-name">
                  <span className="header-stage-name-wrapper">
                    {leagueDetails.leagueLevelUnitName}
                  </span>
                </th>
                <th className="cell-played" title="Kamper spilt">
                  S
                </th>
                <th className="cell-wins" title="Vunnet">
                  V
                </th>
                <th className="cell-draws" title="Uavgjort">
                  U
                </th>
                <th className="cell-losses" title="Tap">
                  T
                </th>
                <th className="cell-goals" title="Mål">
                  Mål
                </th>
                <th className="cell-goalsDiff" title="Diff">
                  D
                </th>
                <th className="cell-points" title="Poeng">
                  P
                </th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team: any, index: number) => (
                <tr
                  key={index}
                  className={
                    "stage-table-row " +
                    (index === 0
                      ? "standing-rule-positive-10"
                      : index === 1
                      ? "standing-rule-positive-9"
                      : index === 7
                      ? "standing-rule-negative-10"
                      : "")
                  }
                >
                  <td className="cell-rank">{index + 1}</td>
                  <td className="participant-logo">
                    <div className="team-logo">
                      <img
                        className="okWtf"
                        src={team.logoUrl}
                        srcSet={team.logoUrl}
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="participant-name">
                    <span className="participant-name-wrapper">
                      {team.teamName}{" "}
                      {activeTeam &&
                        team.teamId === activeTeam.teamId &&
                        " <- (You)"}
                    </span>
                  </td>
                  <td className="cell-played">{team.matches}</td>
                  <td className="cell-wins">{team.won}</td>
                  <td className="cell-draws">{team.draws}</td>
                  <td className="cell-losses">{team.lost}</td>
                  <td className="cell-goals">
                    {team.goalsFor} - {team.goalsAgainst}
                  </td>
                  <td className="cell-goalsDiff">
                    {team.goalsFor - team.goalsAgainst}
                  </td>
                  <td className="cell-points">{team.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="standings-rules-legend">
            <div className="standings-rules-legend-item standing-rule-positive-10">
              Promotion
            </div>
            <div className="standings-rules-legend-item standing-rule-positive-9">
              Promotion qualifyers
            </div>
            <div className="standings-rules-legend-item standing-rule-negative-10">
              Relegations
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeagueStandings;
