import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import React from "react";
import { Link, useParams } from "react-router-dom";
import "../css/App.css";
import { baseURL } from "../helpers/BaseUrls";
// import theme from './ForgotPassword.module.scss';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

enum AlertMessage {
  Incorrect = "Something went wrong, probably old token!",
  NotFilled = "You have to provide a email!",
  SentLink = "Might have sent you a mail!",
  Success = "Password changed!",
}

enum AlertSeverity {
  Incorrect = "error",
  Success = "success",
}

export interface ForgotPasswordProps {}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({}) => {
  const [alertMsg, setAlertMsg] = React.useState(AlertMessage.Incorrect);
  const [severity, setSeverity] = React.useState<AlertColor>(
    AlertSeverity.Incorrect
  );
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let params = useParams();
  const forgottenToken = params["token"];

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmitNewPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: data.get("password"),
      }),
    };
    fetch(
      baseURL() + "/api/account/newPassword/" + forgottenToken,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.text;
        }
        throw new Error();
      })
      .then((data) => {
        setAlertMsg(AlertMessage.Success);
        setSeverity(AlertSeverity.Success);
        setOpen(true);
      })
      .catch((error) => {
        setAlertMsg(AlertMessage.Incorrect);
        setSeverity(AlertSeverity.Incorrect);
        setOpen(true);
      });
  };

  const handleSubmitPasswordRequest = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    if (email === null || email === undefined || email.toString().length < 1) {
      setAlertMsg(AlertMessage.NotFilled);
      setSeverity(AlertSeverity.Incorrect);
      setOpen(true);
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
      },
    };
    fetch(baseURL() + "/api/account/forgotPassword/" + email, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text;
        }
        throw new Error();
      })
      .then((data) => {
        setAlertMsg(AlertMessage.SentLink);
        setSeverity(AlertSeverity.Success);
        setOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        setAlertMsg(AlertMessage.Incorrect);
        setSeverity(AlertSeverity.Incorrect);
        setOpen(true);
        setLoading(false);
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Tippeligaen!</h1>
      </header>
      <div className="App-body">
        {!forgottenToken ? (
          <Box
            component="form"
            onSubmit={handleSubmitPasswordRequest}
            noValidate
            sx={{
              mt: 1,
              color: "success.main",
            }}
          >
            <Typography
              gutterBottom
              sx={{ margin: 0, padding: 0 }}
              variant="h6"
              className="smile"
              component="span"
            >
              Type your email to get a link with password reset instructions.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="off"
            />

            <Button
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Reset password
            </Button>
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmitNewPassword}
            noValidate
            sx={{
              mt: 1,
              color: "success.main",
            }}
          >
            <Typography
              gutterBottom
              sx={{ margin: 0, padding: 0 }}
              variant="h6"
              className="smile"
              component="span"
            >
              Enter your new password.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="off"
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              Update password
            </Button>
            <Link type="button" to="/">
              <Button
                type="button"
                fullWidth
                variant="text"
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                Back to home
              </Button>
            </Link>
          </Box>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
