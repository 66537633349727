import { gsap } from "gsap";
import React, { Component } from "react";
import { TeamDTO } from "../App";
import "../css/App.css";
import { fetchAllMatches, getAllLeagueUsers } from "../helpers/APIHelper";
import { Match } from "../model/hattrick/Match";
import Loading from "./Loading";
import RoundBoard from "./RoundBoard";
import RoundScoreBoard from "./RoundScoreBoard";
import ScoreBoard from "./ScoreBoard";

type MyProps = {
  matches: Match[];
  roundId: any;
  seasonId: number;
  currentUser: any;
  activeTeam: TeamDTO | null | undefined;
};
type MyState = {
  totalScoreBoard: any;
  scoreBoard: any;
  roundMatches: any;
  allMatchesWithBettings: any;
  users: any;
  loading: boolean;
};
class Results extends Component<MyProps, MyState> {
  private boxRef: React.RefObject<HTMLDivElement>;

  constructor(props: MyProps) {
    super(props);
    this.boxRef = React.createRef();
    this.state = {
      totalScoreBoard: null,
      scoreBoard: null,
      roundMatches: null,
      allMatchesWithBettings: null,
      users: null,
      loading: true,
    };
  }

  async componentDidMount() {
    const { currentUser, roundId, seasonId, activeTeam } = this.props;
    if (activeTeam) {
      const allMatchesWithBettings = await fetchAllMatches(
        currentUser.userToken,
        activeTeam!.leagueId,
        seasonId
      );
      const users = await getAllLeagueUsers(
        currentUser.userToken,
        activeTeam!.leagueId
      );
      let roundMatches = allMatchesWithBettings.find((roundMatches: any) => {
        return roundMatches.roundId === roundId;
      });
      this.setState(
        {
          allMatchesWithBettings: allMatchesWithBettings,
          roundMatches: roundMatches,
          users: users,
          loading: false,
        },
        () => {
          this.updateTotalScoreBoard();
        }
      );
    }

    gsap.fromTo(
      this.boxRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    );
  }

  componentDidUpdate = () => {
    const { roundId, activeTeam } = this.props;

    let { roundMatches, allMatchesWithBettings } = this.state;
    if (roundId !== roundMatches?.roundId) {
      roundMatches = allMatchesWithBettings.find((roundMatches: any) => {
        return roundMatches.roundId === roundId;
      });
      this.setState(
        {
          roundMatches: roundMatches,
        },
        () => {
          this.updateThisScoreBoard();
        }
      );
    }
  };

  calculateScore = (homeScore: any, awayScore: any, userBet: any) => {
    if (homeScore === null || homeScore === undefined || isNaN(homeScore)) {
      return -1;
    }
    homeScore = parseInt(homeScore);
    awayScore = parseInt(awayScore);
    userBet.homeScore = parseInt(userBet.homeScore);
    userBet.awayScore = parseInt(userBet.awayScore);

    const homeWin = homeScore > awayScore;
    const awayWin = awayScore > homeScore;
    const tie = awayScore === homeScore;
    const userBetHomeWin = userBet.homeScore > userBet.awayScore;
    const userBetAwayWin = userBet.homeScore < userBet.awayScore;
    const userBetTie = userBet.homeScore === userBet.awayScore;

    if (homeScore === userBet.homeScore && awayScore === userBet.awayScore) {
      return 5;
    } else if (tie && userBetTie) {
      return 4;
    } else if ((homeWin && userBetHomeWin) || (awayWin && userBetAwayWin)) {
      if (homeScore - awayScore === userBet.homeScore - userBet.awayScore) {
        return 3;
      } else if (
        homeScore === userBet.homeScore ||
        awayScore === userBet.awayScore
      ) {
        return 3;
      }
      return 2;
    } else if (
      homeScore === userBet.homeScore ||
      awayScore === userBet.awayScore
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  updateTotalScoreBoard = async () => {
    const { allMatchesWithBettings, users } = this.state;

    let totalScoreBoard = users.map((user: any) => {
      return {
        userId: user.userId,
        userName: user.userName,
        score: 0,
        trophies: [],
      };
    });
    allMatchesWithBettings.forEach((roundMatches: any) => {
      roundMatches.matches.map((match: any) => {
        match.userBets.map((userBet: any) => {
          const points =
            this.calculateScore(match.homeGoals, match.awayGoals, userBet) ??
            -1;
          if (points >= 0) {
            totalScoreBoard.map((user: any) => {
              if (user.userId === userBet.userId) {
                user.score += points;
              }
              return user;
            });
            userBet.points = points;
          }
          return userBet;
        });
        return match;
      });
      totalScoreBoard = totalScoreBoard.sort((a: any, b: any) =>
        a.score < b.score ? 1 : -1
      );

      this.setState({ totalScoreBoard: totalScoreBoard }, () => {
        for (let i = 1; i < 15; i++) {
          let roundMatches = allMatchesWithBettings.find(
            (match: any) => match.roundId === i
          );
          this.updateScoreBoard(i, roundMatches);
        }
        this.updateThisScoreBoard();
      });
    });
  };

  updateThisScoreBoard = () => {
    const { roundId } = this.props;
    const { allMatchesWithBettings } = this.state;

    let roundMatches = allMatchesWithBettings.find((roundMatches: any) => {
      return roundMatches.roundId === roundId;
    });
    this.updateScoreBoard(roundId, roundMatches);
  };

  updateScoreBoard = (roundId: number, roundMatches: any) => {
    let { totalScoreBoard, users } = this.state;
    if (roundMatches?.matches.length === 0) {
      this.setState({ scoreBoard: null });
      return;
    }

    let scoreBoard = {
      round: roundId,
      scores: users.map((user: any) => {
        return {
          userId: user.userId,
          userName: user.userName,
          score: -1,
        };
      }),
    };

    roundMatches?.matches.map((match: any) => {
      match.userBets.map((userBet: any) => {
        const points =
          this.calculateScore(match.homeGoals, match.awayGoals, userBet) ?? -1;
        if (points >= 0) {
          scoreBoard.scores.map((user: any) => {
            if (user.userId === userBet.userId) {
              if (user.score === -1) {
                user.score = 0;
              }
              user.score += points;
            }
            return user;
          });
          userBet.points = points;
        }
        return userBet;
      });
      return match;
    });
    scoreBoard.scores = scoreBoard.scores.sort((a: any, b: any) =>
      a.score < b.score ? 1 : -1
    );

    //Set tophy
    totalScoreBoard = totalScoreBoard.map((user: any) => {
      let exists = user.trophies.find((trophy: any) => {
        return trophy.roundId === scoreBoard.round;
      });
      if (!exists) {
        scoreBoard.scores
          .filter(
            (userScore: any) =>
              userScore.score === scoreBoard.scores[0].score &&
              scoreBoard.scores[0].score > -1 &&
              userScore.userId === user.userId
          )
          .forEach(() => {
            user.trophies = [...user.trophies, { roundId: scoreBoard.round }];
          });
      }
      return user;
    });
    this.setState({ scoreBoard: scoreBoard, totalScoreBoard: totalScoreBoard });
  };

  render() {
    const { matches, roundId } = this.props;
    const { totalScoreBoard, roundMatches, scoreBoard, loading } = this.state;

    let matchDTO = matches.map((match: any) => {
      return {
        ...match,
        matchResult: roundMatches?.matches.find((rMatch: any) => {
          return rMatch.matchId === match.matchId;
        }),
      };
    });

    return (
      <div>
        {loading ? (
          <Loading text="Loading results..." />
        ) : (
          <div ref={this.boxRef}>
            <div className="scoreBody">
              <ScoreBoard totalScoreBoard={totalScoreBoard} />
              {scoreBoard ? (
                <RoundScoreBoard scoreBoard={scoreBoard} matches={matchDTO} />
              ) : (
                ""
              )}
            </div>
            <div className="roundBody">
              {matchDTO.map((match: any) => (
                <RoundBoard
                  key={match.matchId}
                  match={match}
                  roundId={roundId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Results;
